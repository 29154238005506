var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { ok: _vm.modalOk, hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "b-button",
                          {
                            staticClass: "history-button",
                            attrs: { variant: "secondary", size: "sm" },
                            on: {
                              click: function ($event) {
                                _vm.state.historyShow = true
                              },
                            },
                          },
                          [
                            _c("font-awesome-icon", {
                              attrs: { icon: ["far", "clock-rotate-left"] },
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("button.history")) + " "
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.isAccessDenied && _vm.canEdit()
                    ? _c(
                        "b-button",
                        {
                          attrs: { size: "sm", variant: "success" },
                          on: { click: _vm.modalOk },
                        },
                        [_vm._v(_vm._s(_vm.$t("button.ok")))]
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("tag.title_singular").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("tag.title_singular").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.errorShow,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _vm.isNameVisible
                      ? _c(
                          "b-col",
                          { attrs: { cols: "12" } },
                          [
                            _c(
                              "b-form-group",
                              {
                                class: { "mb-0": _vm.showNameError },
                                attrs: {
                                  label: _vm.$t("tag.field.name"),
                                  "label-for": "name",
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  [
                                    _c("b-form-input", {
                                      directives: [
                                        {
                                          name: "validate",
                                          rawName: "v-validate",
                                          value: { required: true },
                                          expression: "{ required: true }",
                                        },
                                      ],
                                      attrs: {
                                        id: "name",
                                        type: "text",
                                        "data-vv-as": _vm.$t("tag.field.name"),
                                        "data-vv-name": "tag.name",
                                        "data-vv-delay": "500",
                                        readonly: _vm.isNameReadOnly,
                                        state:
                                          _vm.fieldValidateUtil.stateValidate(
                                            _vm.isReadOnly,
                                            _vm.veeFields,
                                            _vm.errors,
                                            "tag.name"
                                          ),
                                        autofocus: "",
                                        trim: "",
                                      },
                                      model: {
                                        value: _vm.tag.name,
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tag, "name", $$v)
                                        },
                                        expression: "tag.name",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-invalid-feedback",
                                  {
                                    staticClass:
                                      "alert-danger form-field-alert",
                                    class: { "d-block": _vm.showNameError },
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      attrs: {
                                        icon: ["far", "circle-exclamation"],
                                      },
                                    }),
                                    _vm._v(
                                      "  " +
                                        _vm._s(_vm.errors.first("tag.name")) +
                                        " "
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.customFieldMap["name"] != null
                      ? _vm._l(
                          _vm.customFieldMap["name"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: "name" + index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.tag[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tag, field.name, $$v)
                                        },
                                        expression: "tag[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                    _vm.customFieldMap["default"] != null
                      ? _vm._l(
                          _vm.customFieldMap["default"],
                          function (field, index) {
                            return _c(
                              "b-col",
                              {
                                key: index,
                                staticClass: "pr-0",
                                attrs: { cols: "12" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  [
                                    field.type !== "Boolean"
                                      ? _c("template", { slot: "label" }, [
                                          _c("span", { staticClass: "mr-2" }, [
                                            _vm._v(_vm._s(field.displayName)),
                                          ]),
                                          field.description
                                            ? _c(
                                                "span",
                                                [
                                                  _c("font-awesome-icon", {
                                                    style: {
                                                      color:
                                                        "var(--form-control-placeholder)",
                                                      fontSize: "0.9em",
                                                    },
                                                    attrs: {
                                                      id:
                                                        _vm.componentId +
                                                        "_" +
                                                        field.name,
                                                      icon: [
                                                        "far",
                                                        "circle-question",
                                                      ],
                                                    },
                                                  }),
                                                  _c(
                                                    "b-popover",
                                                    {
                                                      attrs: {
                                                        target:
                                                          _vm.componentId +
                                                          "_" +
                                                          field.name,
                                                        triggers: "hover",
                                                        placement: "top",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            field.description
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ])
                                      : _vm._e(),
                                    _c("CustomField", {
                                      attrs: {
                                        componentId: _vm.componentId,
                                        field: field,
                                        disabled:
                                          _vm.isReadOnly ||
                                          (_vm.exists &&
                                            !_vm.canEdit(_vm.permissionName, [
                                              field.name,
                                            ])),
                                      },
                                      model: {
                                        value: _vm.tag[field.name],
                                        callback: function ($$v) {
                                          _vm.$set(_vm.tag, field.name, $$v)
                                        },
                                        expression: "tag[field.name]",
                                      },
                                    }),
                                  ],
                                  2
                                ),
                              ],
                              1
                            )
                          }
                        )
                      : _vm._e(),
                  ],
                  2
                ),
              ],
        ],
        2
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "TAG",
                    customFields: _vm.customFields,
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }